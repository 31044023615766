<template>
    <div>
        <!-- Preview File Modal -->
        <b-modal scrollable :title="selectedFile" class="modal-dark" v-model="filePreviewModal" size="lg">
            <div style="display: flex; justify-content: center;">
                <div v-if="filePreviewType === 'loading'">
                    <fa-icon :icon="['fas', 'spinner']" spin size="lg" style="margin-right: 10px;"/>
                    <span><b>Loading File</b></span>
                </div>
                <video v-if="filePreviewType === 'video'"
                       width="768"
                       height="432"
                       :src="filePreview"
                       playsinline muted autoplay controls/>
                <img v-if="filePreviewType === 'image'" :src="filePreview" style="max-width: 700px; max-height: 700px" alt=""/>
                <embed v-if="filePreviewType === 'pdf'" :src="filePreview" style="width: 100%; height: 825px"/>
            </div>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="filePreviewModal = false;">
                    Cancel
                </b-button>
                <b-button size="sm" variant="primary" @click="downloadFile(selectedFile)">
                    Download
                </b-button>
            </template>
        </b-modal>

        <b-card style="width: 100%; height: 100%; margin-bottom: 10px; background-color: #1c1c1c; border: 0; border-radius: 0" no-body>
            <div slot="header" style="height: 29px" class="d-flex align-items-center">
                <strong>{{title}}s</strong>
            </div>
            <div style="height: 100%; overflow-y: hidden; padding-top: 15px">
                <FileUpload v-if="reportUserId === userId && reportStatus !== 'Approved' && reportStatus !== 'Pending Approval'"
                            :files="sortedFiles"
                            :fileNames="fileNames"
                            :uploadStatus="uploadStatus"
                            :getFiles="getFiles"
                            :newNames="false"
                            :vertical="true"
                            :selfUpload="true"
                            :height="300"
                            @returnFiles="uploadFiles"/>
                <div v-if="filePreviews.length > 0" style="overflow-y: auto; height: calc(100% - 350px)">
                    <div v-for="(file, index) in filePreviews">
                        <File :file="file"
                              :key="file.etag"
                              :title="title"
                              :editable="editable"
                              :reportStatus="reportStatus"
                              :userName="userName"
                              :userId="userId"
                              :isSupervisor="isSupervisor"
                              :index="index"
                              :reportId="reportId"
                              @downloadFile="downloadFile"
                              @deleteFile="deleteFile"
                              @showImagePreview="showImagePreview"/>
                    </div>
                </div>
                <div v-else style="color: white; text-align: center; padding-top: 10px; font-size: 20px;">
                    No Available Files
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import File from './ReportFile';
import FileUpload from '@/components/FileUpload';
import Report from "@/services/report";

export default {
    name: "ReportFilesTab",
    components: {
        File,
        FileUpload
    },
    props: {
        editable: Boolean,
        title: String,
        reportId: String,
        userName: String,
        userId: String,
        isSupervisor: Boolean,
        reportUserId: String,
        reportStatus: String
    },
    data() {
        return {
            filesUploadingCount: 0,
            newFiles: [],
            filePreviewModal: false,
            filePreview: '',
            filePreviewType: '',
            newDesc: [],
            selectedFile: '',
            sortedFiles: [],
            fileNames: [],
            filePreviews: [],
            uploadStatus: '',
            getFiles: false,
            pdfDoc: null,
        };
    },
    created() {
        this.getReportFiles();
    },
    methods: {
        deleteFile(fileName) {
            Report.deleteFile({reportId: this.reportId, fileName: fileName}).then(() => {
                this.getReportFiles();
            });
        },
        downloadFile(fileName) {
            Report.downloadFile({reportId: this.reportId, fileName: fileName}).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            });
        },
        showImagePreview(data) {
            if (this.reportUserId === this.userId || this.isSupervisor === true) {
                if (data.type === 'jpg' || data.type === 'jfif' || data.type === 'png' || data.type === 'pdf' || data.type === 'mp4' || data.type === 'jpeg') {
                    this.selectedFile = data.name;
                    this.filePreviewType = 'loading';
                    this.filePreviewModal = true;
                    Report.downloadFile({reportId: this.reportId, fileName: data.name}).then(async response => {
                        if (data.type === 'pdf') {
                            this.filePreviewType = 'pdf';
                            this.filePreview = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                        } else if (data.type === 'mp4') {
                            this.filePreviewType = 'video';
                            this.filePreview = window.URL.createObjectURL(new Blob([response.data], {type: 'video/mp4'}));
                        } else {
                            this.filePreviewType = 'image';
                            this.filePreview = window.URL.createObjectURL(new Blob([response.data], {type: 'image/png'}));
                        }
                    })
                }
            }
        },
        getReportFiles() {
            if(this.reportId !== undefined) {
                Report.getReportFiles(this.reportId).then(response => {
                    this.filesUploadingCount--;
                    this.filePreviews = response.data;
                    if (this.filesUploadingCount === 0) {
                        this.newFiles = this.newDesc = [];
                        this.uploadStatus = '';
                    }
                })
            }
        },
        async uploadFiles(data) {
            this.uploadStatus = 'Uploading';
            this.newFiles = data.files;
            this.newDesc = data.fileNames;
            this.filesUploadingCount = 0;
            let count = 0;
            for (let i = 0; i < this.newFiles.length; i++) {
                this.filesUploadingCount++;
                let formData = new FormData();
                formData.append('fileData', this.newFiles[i]);
                formData.append('reportId', this.reportId);
                formData.append('fileDesc', this.newDesc[i]);
                formData.append('userName', this.userName);
                formData.append('userId', this.userId);
                await Report.uploadFile(formData).then(async () => {
                    await this.getReportFiles();
                    count++;
                    if (count === this.newFiles.length) {
                        await Report.deleteUploadFolder();
                    }
                });
            }
        }
    },
}
</script>

<style scoped>
</style>