<template>
    <div :id="reportItem._id">
        <b-modal v-model="confirmModalBool" :title="'Are you sure you want to '+confirmType+' this item?'" hide-footer>
            <div class="d-flex justify-content-center">
                <b-button variant="secondary" @click="confirmModalBool = false;" style="margin-right: 15px;">
                    Cancel
                </b-button>
                <b-button variant="primary"  @click="confirmSelection">
                    Confirm
                </b-button>
            </div>
        </b-modal>
        <b-card class="main-b-card" no-body style="margin: 0; border: 0; background-color: #1c1c1c" @click="openTab">
            <div slot="header">
                <span style="font-size: 16px">
                    {{item.name + ' ' + (index + 1)}}
                    <fa-icon v-if="!isSavable" :icon="['fas', 'triangle-exclamation']" style="color: #b4b428; font-size: 14px" title="Required Field Empty"/>
                </span>
                <div class="card-header-actions">
                    <fa-icon v-if="editable === true && length > 1"
                             :icon="['fas', 'trash-can']"
                             style="color:white; padding-right: 12px; font-size: 21px; cursor: pointer;"
                             title="Delete Item"
                             @click.stop="openConfirmModal('delete')"/>
                    <fa-icon v-if="editable === true"
                             :icon="['fas', 'eraser']"
                             style="color:white; padding-right: 12px; font-size: 21px; cursor: pointer;"
                             title="Clear Item"
                             @click.stop="openConfirmModal('clear')" />
                    <fa-icon v-if="editable === true"
                             :icon="['far', 'copy']"
                             style="color:white; padding-right: 8px; font-size: 21px; cursor: pointer;"
                             title="Duplicate Item"
                             @click.stop="duplicateItem" />
                    <b-link class="card-header-action btn-minimize">
                        <fa-icon v-if="toggle === true" :icon="['fas', 'angle-up']" style="color: white; font-size: 18px" title="Close Item"/>
                        <fa-icon v-else :icon="['fas', 'angle-down']" style="color: white; font-size: 18px" title="Open Item"/>
                    </b-link>
                </div>
            </div>
            <div @click.stop>
                <b-collapse v-model="toggle" visible class="itemBackgroundColor">
                    <Field :editable="editable"
                           :item="item"
                           :index="index"
                           :google="google"
                           :categoryId="categoryId"
                           :categoryName="categoryName"
                           :reportItem="reportItem"
                           :singleItem="singleItem"
                           :highlightFields="selectedField.values"
                           :localItem="localItem"
                           @selectField="selectField"/>
                </b-collapse>
            </div>
        </b-card>
    </div>
</template>

<script>

import Report from "@/services/report";
import Field from "@/views/expansion_modules/reports/case_components/Field";
import PubSub from "pubsub-js";
import datePicker from "vue-bootstrap-datetimepicker";
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import MaskedInput from 'vue-text-mask';

export default {
    name: "Item",
    components: {
        datePicker,
        MaskedInput,
        Field,
    },
    props: {
        length: Number,
        editable: Boolean,
        index: Number,
        item: Object,
        categoryId: String,
        categoryName: String,
        reportItem: Object,
        singleItem: Boolean,
        google: HTMLScriptElement
    },
    data() {
        return {
            pubsubs: [],
            localItem: {},
            selectedAddress: '',
            selectedField: {values: [{id: null}]},
            storedCharacters: '',
            showSearchQueries: false,
            searchResults: [],
            searchingForResults: false,
            toggle: false,
            pageLoad: false,
            confirmModalBool: false,
            confirmType: null,
        }
    },
    beforeDestroy() {
        PubSub.publish('requiredFormItems', {
            categoryName: this.categoryName,
            categoryId: this.categoryId,
            itemId: this.reportItem._id,
            errorsArr: [],
            itemName: this.item.name,
            itemIndex: this.index + 1,
        });
        this.pubsubs.forEach(pubsub => {
            PubSub.unsubscribe(pubsub);
        });
    },
    created() {
        this.localItem = Object.assign({}, this.reportItem);
        //make sure that if a new field is an object, that it has an object to fill into
        this.item.fields.forEach(field => {
            if (field.type === 'officer' || field.type === 'inmate' || field.type === 'address') {
                if (typeof this.reportItem[field._id] !== 'object') {
                    if (field.type === 'officer') {
                        this.localItem[field._id] = {
                            firstName: '',
                            lastName: '',
                            starNumber: '',
                        };
                    } else if (field.type === 'inmate') {
                        this.localItem[field._id] = {
                            firstName: '',
                            middleName: '',
                            lastName: '',
                            dob: '',
                            ssn: '',
                            mid: '',
                        };
                    } else if (field.type === 'address') {
                        this.localItem[field._id] = {
                            address: '',
                            apartment: '',
                            city: '',
                            state: '',
                            zip: ''
                        };
                    }
                }
            }
        })
        this.pubsubs.push(PubSub.subscribe('openChild', (msg, data) => {
            if (data.itemId === this.reportItem._id) {
                this.toggle = (data.open ? true : !this.toggle);
                PubSub.publish('focusField'+this.index, Object.assign(data, {index: this.index}));
            }
        }));
        this.pubsubs.push(PubSub.subscribe('closeOpenAllOpenIncidentTabs', (msg, data) => {
            this.toggle = data.bool;
        }));
        //this.$emit('saveItem', {item: this.localItem, index: this.index, savable: this.isSavable});
    },
    methods: {
        openConfirmModal(type) {
            this.confirmType = type;
            this.confirmModalBool = true;
        },
        confirmSelection() {
            this.confirmModalBool = false;
            if (this.confirmType === 'delete') {
                this.removeItem();
            } else if (this.confirmType === 'clear') {
                this.clearItem();
            }
            this.confirmType = null;
        },
        selectField(field) {
            this.selectedField = field;
        },
        removeItem() {
            this.$emit('removeItem', {index: this.index, itemId: this.reportItem._id});
        },
        duplicateItem() {
            this.$emit('duplicateItem', {item: this.reportItem, itemIndex: this.index});
        },
        clearItem() {
            for (const field in this.localItem) {
                if (this.localItem[field] !== this.reportItem._id) {
                    if (typeof this.localItem[field] === 'object') {
                        for (const content in this.localItem[field]) {
                            this.localItem[field][content] = '';
                        }
                    } else {
                        this.localItem[field] = '';
                    }
                }
            }
            this.$mToast({
                title: "Item's Content Cleared" ,
                style: 'success',
            });
        },
        openTab() {
            this.toggle = !this.toggle;
        },
        filledOut(i) {
            if (typeof i === 'object' && i !== null) {
                for (let index = 0; index < Object.keys(i).length; index++) {
                    if (this.filledOut(i[Object.keys(i)[index]])) {
                        return true;
                    }
                }
                return false;
            } else {
                return i !== null && i !== undefined && i !== '' && i !== 0 && i !== false;
            }
        },
    },
    watch: {
        toggle() {
            PubSub.publish('toggleItemIcon', this.reportItem._id);
        },
        localItem: {
            handler() {
                if (this.pageLoad === true) {
                    if (this.localItem !== null && this.localItem !== undefined && this.localItem !== {}) {
                        this.$emit('saveItem', {item: this.localItem, index: this.index, savable: this.isSavable});
                        PubSub.publish('autoSaveRequest', {timer: 15000});
                    }
                } else {
                    //this will execute on page load unless we prevent it with this
                    this.pageLoad = true;
                    this.$emit('saveItem', {item: this.localItem, index: this.index, savable: this.isSavable});
                }
            },
            deep: true
        },
    },
    computed: {
        savable() {
            let array = [];
            let errorsArr = [];
            for (let i = 0; i < this.item.fields.length; i++) {
                let status = (this.item.fields[i].required && this.filledOut(this.localItem[this.item.fields[i]._id]))
                    || !this.item.fields[i].required;
                array.push(status);
                if (!status) {
                    errorsArr.push(this.item.fields[i]);
                }
            }
            return array;
        },
        isSavable() {
            let result = true;
            for (let i = 0; i < this.savable.length; i++) {
                if (this.savable[i] === false) {
                    result = false;
                    break;
                }
            }
            return result;
        }
    }
}

</script>

<style scoped>
.card-header {
    background-color: #2a2a2a;
    padding: 12px 20px !important;
    transition: background-color 500ms;
}
.card-header:hover {
    background-color: #141414;
}
.address-b-card .card-header:hover {
    background-color: #121212 !important;
}
.itemBackgroundColor {
    background-color: #424246;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #808080;
    opacity: 1; /* Firefox */
}
.form-control:disabled {
    background-color: #3a3a3a;
}
</style>