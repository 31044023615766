<template>
    <div class="mainContainer d-flex justify-content-between">
        <!-- Import Report Modal -->
        <csv-import :categories="template.categories"
                    :importLoading="importLoading"
                    :importSingleCategory="importSingleCategory"
                    :importWholeReport="importWholeReport"/>
        <!-- Add Report Modal -->
        <b-modal no-close-on-backdrop scrollable title="Add Report Type" class="modal-dark" v-model="addReportModal">
            <b-row>
                <b-col sm="12">
                    <b-form-group>
                        <label>Select Template for Report</label>
                        <b-form-select v-model="selectedTemplate"
                                       :options="templates.map(_ => { return {text: _.name, value: _._id}})"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="addReportModal = false;">
                    Cancel
                </b-button>
                <b-button size="sm" variant="primary" :disabled="selectedTemplate === ''" @click="createNewReport">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <!-- Change Status Modal -->
        <b-modal no-close-on-backdrop scrollable title="Change Report Status" class="modal-dark" v-model="changeReportStatusModal">
            <b-row>
                <b-col sm="12">
                    <b-form-group>
                        <label>Select Report Status</label>
                        <b-form-select v-model="reportStatus" :options="reportStatuses"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="changeReportStatusModal = false;">
                    Cancel
                </b-button>
                <b-button size="sm" variant="primary" :disabled="reportStatus === ''" @click="changeReportStatus">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <!-- Assign Users Modal -->
        <b-modal no-close-on-backdrop scrollable title="Assign Users" class="modal-dark" v-model="assignUserModel" size="lg">
            <v-client-table ref="dTable" style="width: 100%; padding: 10px" :columns="assignUserTable.columns.concat(['select', 'primary'])" :data="users"
                            :options="assignUserTable.tOptions" :theme="assignUserTable.theme" class="dataTable">
                <div slot="select" slot-scope="props" style="float: right; height: 29px">
                    <b-button v-if="caseObj.assigned_users.indexOf(props.row.sub) === -1" variant="primary" @click="assignCase(caseObj._id, props.row.sub)" size="sm">Assign</b-button>
                    <b-button v-else variant="danger" @click="unassignCase(caseObj._id, props.row.sub)" size="sm">Unassign</b-button>
                </div>
                <div slot="h__select" style="float: right">
                    <span style="margin-bottom: 0">Assign</span>
                </div>
                <div slot="primary" slot-scope="props" style="float: right; height: 29px">
                    <b-button v-if="checkPrimaryUser(props.row.sub, result => result === true)" style="padding: 0; height: 16px; width: 16px;" variant="primary">
                        <fa-icon :icon="['fas', 'check']" fixed-width style="font-size: 14px; color: black; left: -1px; top: -4px; position: relative"/>
                    </b-button>
                    <b-button v-else @click="assignPrimaryUser(props.row.sub)" style="padding: 7px;" variant="light"></b-button>
                </div>
                <div slot="h__primary" style="float: right">
                    <span style="margin-bottom: 0">Primary</span>
                </div>
            </v-client-table>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="assignUserModel = false">
                    Exit
                </b-button>
            </template>
        </b-modal>

        <!-- Delete Report Modal -->
        <b-modal no-close-on-backdrop scrollable title="Delete Report" class="modal-dark" v-model="deleteReportModal">
            <b-row>
                <b-col sm="12">
                    <b-form-group>
                        <label>Are you sure you want to delete this report?</label>
                    </b-form-group>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="deleteReportModal = false;">
                    No
                </b-button>
                <b-button size="sm" variant="primary" @click="deleteReport">
                    Yes
                </b-button>
            </template>
        </b-modal>

        <!-- Choose prebuilt PDF Modal -->
        <b-modal no-close-on-backdrop scrollable class="modal-dark" v-model="choosePdfModal">
            <div slot="modal-header">
                <h5>Choose PDF to fill out.</h5>
            </div>
            <b-input-group>
                <b-form-select v-model="selectedPdf"
                               :options="[{value: '', text: ''}].concat(pdfList)"
                               style="cursor:pointer;"/>
            </b-input-group>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="choosePdfModal = false;">
                    Cancel
                </b-button>
                <b-button size="sm" variant="primary" :disabled="selectedPdf === ''" @click="getSelectedPdf">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <!-- Fill prebuilt PDF Modal -->
        <b-modal no-close-on-backdrop :title="selectedPdfName" class="modal-dark" v-model="pdfFileModal" size="xl">
            <div style="overflow-y: hidden;">
                <embed :src="pdfFile" style="width: 100%; height: 80vh;"/>
            </div>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="pdfFileModal = false;" style="margin-right: 10px; float: right">
                    Cancel
                </b-button>
            </template>
        </b-modal>

        <!-- PDF Preview Modal -->
        <b-modal size="xl" title="PDF Preview" class="modal-dark" v-model="showPdf">
            <div v-if="gettingReport === false && showPdf === true" style="display: flex; justify-content: center">
                <PdfPreview :template="template.categories"
                            :categories="report.report.categories"
                            :user="report.assigned_user.name"
                            :reportDate="report.time_submitted"
                            :caseId="caseObj._id"/>
            </div>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="showPdf = false;">
                    Cancel
                </b-button>
                <b-button size="sm" variant="primary" @click="downloadPdfMethod">
                    Download
                </b-button>
            </template>
        </b-modal>

        <!-- PDF Download Link -->
        <div v-if="gettingReport === false && template.categories !== undefined" style="display: none">
            <PdfPreview :template="template.categories"
                        :categories="report.report.categories"
                        :user="report.assigned_user.name"
                        :caseId="caseObj._id"
                        @createPdfDownloadLink="createPdfDownloadLink"/>
        </div>

        <!-- Left Side Menu: Fast Nav -->
        <div id="column1" style="width: 14%; min-width: 14%">
            <b-card style="width: 100%; height: 100%; overflow-y: auto; overflow-x: hidden; border: 0; border-radius: 0;" no-body class="pageBackground">
                <div slot="header">
                    <div style="height: 29px" class="d-flex align-items-center justify-content-center">
                        <strong>Fast NAV</strong>
                    </div>
                </div>
                <div style="overflow-y: auto; overflow-x: hidden; width: 100%; height: 100%">
                    <NavBar v-for="(category, index) in template.categories"
                            :key="category._id + newUniqueKey"
                            :category="category"
                            :reportCategory="report.report.categories[index]"
                            @openTab="openTab"
                            @openSingleItemTab="openSingleItemTab"
                            @focusTab="focusTab"/>
                </div>
            </b-card>
        </div>

        <!-- Left Side Closing Bar -->
        <div class="d-flex justify-content-center align-items-center" @click="toggleLeftColumn" style="cursor: pointer; background-color: #131313; z-index: 1">
            <fa-icon :icon="['fas', 'caret-' + (leftBool ? 'right' : 'left')]" size="2x" fixed-width title="Minimize Left SideBar" style="margin-right: 2px;"/>
        </div>

        <!-- Main Page:  -->
        <b-card no-body class="flex-grow-1 pageBackground" style="height: 100%; border: 0; border-radius: 0">
            <div slot="header" style="min-width: 100%">
                <div style="display: flex; padding-top: 3px;" class="caseHeader">
                    <div style="flex: 5; font-size: 16px;">
                        <b>Case: {{caseObj.caseNumber}}</b> {{report.templateName}}
                    </div>
                    <div style="flex: 5;">
                        <b-input-group size="sm" prepend="Report Number">
                            <b-form-select :options="reportNames" v-model="selectedReport"/>
                        </b-input-group>
                    </div>
                    <div style="flex: 4;">
                        <b-dropdown v-if="selectedReport === '' && checkUserIsAssigned" variant="primary dropdown-toggle" size="sm" text="Actions" style="float: right">
                            <b-dropdown-item v-if="user.id === caseObj.assigned_users[0]" @click="beginAction('add')" size="sm">Add Report</b-dropdown-item>
                            <b-dropdown-item v-else @click="beginAction('supplement first')" size="sm">Add Supplement</b-dropdown-item>
                        </b-dropdown>
                        <b-dropdown v-else-if="selectedReport !== ''" variant="primary dropdown-toggle" size="sm" text="Actions" style="float: right">
                            <b-dropdown-item v-if="user.id === caseObj.assigned_users[0] && checkForPrimaryReport && checkUserIsAssigned && isRecords === false" @click="beginAction('add')" size="sm">Add Report</b-dropdown-item>
                            <b-dropdown-item v-if="checkUserIsAssigned && isRecords === false" @click="beginAction('supplement')" size="sm">Create Supplement Report</b-dropdown-item>
                            <b-dropdown-item v-for="action in actions"
                                             :key="action.value"
                                             v-if="action.credentials === 'user'
                                             || isSupervisor === true && action.credentials === 'supervisor'
                                             || isAdmin === true"
                                             @click="beginAction(action.value)"
                                             size="sm">{{action.text}}</b-dropdown-item>
                            <b-dropdown-item v-if="isPREA === true"
                                             @click="beginAction(caseObj.isPREA === true ? 'make case not PREA' : 'make case PREA')"
                                             size="sm">{{ caseObj.isPREA === true ? 'Make Case Not PREA' : 'Make Case PREA' }}</b-dropdown-item>
                        </b-dropdown>
                        <b-button v-if="canSaveReport()" style="float: right; margin-right: 10px" variant="success" size="sm" @click="saveReport">Save</b-button>
                        <b-button v-if="report.status === 'Pending Approval' && isSupervisor === true" style="float: right; margin-right: 10px" size="sm" variant="primary" @click="approveReport">Approve</b-button>
                        <b-button v-if="report.assigned_user.id === user.id && report.status === 'In Progress' || report.status === 'Rejected'" style="float: right; margin-right: 10px" :disabled="Object.keys(formErrors).length !== 0 || checkRevisions()" size="sm" @click="submitReport">Submit</b-button>
                        </div>
                </div>
            </div>
            <div v-if="!template || !template.categories || !Array.isArray(template.categories) || template.categories.length === 0"
                 style="padding: 15px;"
                 class="w-100 d-flex flex-column align-items-center justify-content-center">
                <i style="color: #f86c6b"> No Reports Added </i>
                <i style="color: #f86c6b"> Click "Add Report" under "Actions" </i>
            </div>
            <div style="overflow-y: auto;height: 100%; padding: 10px;">
                <Category v-for="(category, index) in template.categories" style="margin-bottom: 10px"
                          :key="category._id + newUniqueKey"
                          :editable="checkAuthorization"
                          :category="category"
                          :reportCategory="report.report.categories[index]"
                          :index="index"
                          :google="google"
                          @saveCategory="saveCategory"
                          @addItem="addItem"
                          @removeItem="removeItem"
                          @duplicateItem="duplicateItem"/>
            </div>
        </b-card>

        <!-- Right Side Closing Bar and Tab Selection -->
        <div class="h-100 d-flex flex-column align-items-center" @click="toggleRightColumn" style="cursor: pointer; background-color: #131313; padding-top: 54px">
            <div v-for="tab in additionalInfoTabs" :key="tab.type+iter">
                <div v-if="selectedReport !== '' || tab.type === 'users'" @click.stop="changeTab(tab)" :style="tab.type === selectedTab ? 'background-color: #20a8d8' : ''" class="rightBarIcon">
                    <fa-icon v-if="!(JSON.stringify(formErrors) === '{}' && tab.type === 'requiredFormItems'
                            || searchResults.length === 0 && tab.type === 'searchResults'
                            || report.status !== 'Rejected' && tab.type === 'revisions'
                            || tab.supervisorOnly === true && isSupervisor === false)
                            || isSupervisor === true && tab.type === 'revisions'"
                             :icon="tab.icon" fixed-width size="lg"
                             style="padding-bottom: 3px; padding-top: 2px;"
                             :title="tab.title"
                             class="carrotIconToggler"
                             :style="tab.type === 'requiredFormItems' && JSON.stringify(formErrors) !== '{}'  ? 'color: yellow': ''"/>
                </div>
            </div>
            <fa-icon :icon="['fas', 'caret-' + (rightBool ? 'left' : 'right')]" size="2x" fixed-width :class="'carrotIconToggler'" title="Minimize Right SideBar" style="margin-right: 0; margin-top: 5px; transform: translateX(-5px);"/>
        </div>

        <!-- Right Side Menu: Documents -->
        <div id="column2" style="width: 20%; min-width: 20%;">
            <div v-if="rightBool === false" style="width: 19.3%; height: 52px; background-color: #131313; position: absolute"></div>
            <div style="width: 100%; height: 100%" class="pageBackground">
                <transition name="slide-fade-right" mode="out-in">
                    <b-card v-if="selectedTab === ''" style="width: 100%; height: 100%; overflow-y: auto; overflow-x: hidden; border: 0; border-radius: 0" no-body class="pageBackground">
                        <div slot="header">
                            <div style="height: 29px" class="d-flex align-items-center justify-content-center">
                                <strong>No Selected Tab</strong>
                            </div>
                        </div>
                    </b-card>

                    <ReportComments v-else-if="selectedTab === 'notes'" class="h-100"
                                    :notes="report.notes"
                                    :userName="user.name"
                                    :userId="user.id"
                                    :reportUserId="report.assigned_user.id"
                                    :isSupervisor="isSupervisor"
                                    :editable="true"
                                    :reportStatus="report.status"
                                    :addMethod="addNote"
                                    :editMethod="editNote"
                                    :deleteMethod="deleteNote"
                                    :title="'Comment'"/>

                    <ReportFiles v-else-if="selectedTab === 'files'" class="h-100"
                                 :key="report._id"
                                 :userName="user.name"
                                 :userId="user.id"
                                 :reportUserId="report.assigned_user.id"
                                 :isSupervisor="isSupervisor"
                                 :editable="true"
                                 :reportStatus="report.status"
                                 :reportId="report._id"
                                 :title="'File'"/>

                    <ReportRevisions v-else-if="selectedTab === 'revisions'" class="h-100"
                                     :notes="report.revisions"
                                     :userName="user.name"
                                     :userId="user.id"
                                     :isSupervisor="isSupervisor"
                                     :isAdmin="isAdmin"
                                     :editable="checkAuthorization"
                                     :reportStatus="report.status"
                                     :changeRevisionStatus="changeRevisionStatus"
                                     :addMethod="addRevision"
                                     :editMethod="editRevision"
                                     :deleteMethod="deleteRevision"
                                     @requestRevisions="rejectReport"
                                     :title="'Revision'"/>

                    <div v-else-if="selectedTab === 'users'" class="h-100">
                        <b-card style="width: 100%; height: 100%; margin-bottom: 10px; border: 0; border-radius: 0" no-body class="pageBackground">
                            <div slot="header" style="height: 29px" class="d-flex align-items-center">
                                <strong>Assigned Users</strong>
                                <b-badge class="badge-success"
                                         style="height: fit-content; align-self: center; cursor: pointer; margin: 5px"
                                         @click="assignUserModel = true">
                                    <fa-icon :icon="['fas', 'plus']"/> Edit
                                </b-badge>
                            </div>
                            <div class="d-flex flex-column" style="overflow-y: auto; overflow-x: hidden; height: 100%">
                                <v-client-table ref="dTable"
                                                style="width: 100%; padding: 10px;"
                                                :columns="assignUserTable.columns.concat(['primary'])"
                                                :data="users.filter(e => caseObj.assigned_users.indexOf(e.sub) !== -1)"
                                                :options="assignUserTable.tOptions"
                                                :theme="assignUserTable.theme" class="dataTable">
                                    <div slot="primary" slot-scope="props" class="d-flex" style="float: right">
                                        <fa-icon v-if="checkPrimaryUser(props.row.sub, result => result === true)"
                                                 :icon="['fas', 'check']"
                                                 style="font-size: 18px; padding-top: 3px; cursor: default"/>
                                    </div>
                                    <div slot="h__primary" style="float: right">
                                        <span style="margin-bottom: 0">Primary</span>
                                    </div>
                                </v-client-table>
                            </div>
                        </b-card>
                    </div>

                    <div v-else-if="selectedTab === 'info'" class="h-100">
                        <b-card style="width: 100%; height: 100%; margin-bottom: 10px; border: 0; border-radius: 0" no-body class="pageBackground">
                            <div slot="header" style="height: 29px" class="d-flex align-items-center">
                                <strong>Report Info</strong>
                            </div>
                            <div class="d-flex justify-content-center flex-column" style="overflow-y: auto; overflow-x: hidden; width: 100%; margin-top: 10px; font-size: 16px;">
                                <span style="margin-bottom: 5px;"><b style="padding-left: 10px"> Report Status:</b> {{report.status || "Unknown"}}</span>
                                <span v-if="caseObj.isPREA === true" style="margin-bottom: 5px;"><b style="padding-left: 10px">Special Status:</b> This is a PREA case</span>
                                <span style="margin-bottom: 5px;"><b style="padding-left: 10px"> Assigned User:</b> {{report.assigned_user.name || "Unknown"}}</span>
                                <span style="margin-bottom: 5px;"><b style="padding-left: 10px"> Created Date:</b> {{new Date(report.created_date).toLocaleString() || "Unknown"}}</span>
                                <span v-if="report.last_saved !== undefined" style="margin-bottom: 5px;"><b style="padding-left: 10px"> Last Saved:</b> {{new Date(report.last_saved).toLocaleString() || "Unknown"}}</span>
                                <span v-if="report.time_submitted !== undefined" style="margin-bottom: 5px;"><b style="padding-left: 10px"> Time Submitted:</b> {{new Date(report.time_submitted).toLocaleString() || "Unknown"}}</span>
                            </div>
                        </b-card>
                    </div>

                    <div v-else-if="selectedTab === 'searchResults'" class="h-100">
                        <b-card style="width: 100%; height: 100%; margin-bottom: 10px; border: 0; border-radius: 0" no-body class="pageBackground">
                            <div slot="header" style="height: 29px" class="d-flex align-items-center">
                                <strong>Search Results</strong>
                            </div>
                            <div class="d-flex flex-column" style="margin: 10px;">
                                <b-form-input v-model="searchTerm" placeholder="Filter Results"/>
                            </div>
                            <div class="d-flex flex-column" style="overflow-y: auto; overflow-x: hidden; height: 100%; padding: 4px">
                                <div v-for="result in (searchTerm !== '' ? filterArr(searchResults, searchTerm, ['firstName', 'lastName', 'mid', 'starNumber', 'aliasAKA']) : searchResults)" style="margin: 4px">
                                    <div class="list-group-item p-0 clearfix rounded" style="background-color: #0d0d0d; cursor: pointer" @click="selectResult(result)">
                                        <div class="d-flex align-items-center w-100">
                                            <fa-icon :icon="['fas', 'circle-user']" size="2x" class="p-2 float-left align-self-center" style="color: white"/>
                                            <div class="h7 mb-0" style="color: #ffffff; padding: 0;">
                                                <div class="text-content" style="margin-right: 2px;">{{ result.lastName }}, {{result.firstName}}</div>
                                                <div v-if="result.dob !== '' && result.dob !== undefined" class="text-muted text-uppercase font-weight-bold" style="font-size: xx-small">Date of Birth: {{result.dateOfBirth}}</div>
                                                <div v-if="result.mid !== '' && result.mid !== undefined" class="text-muted text-uppercase font-weight-bold" style="font-size: xx-small">MID: {{result.mid}}</div>
                                                <div v-if="result.aliasAKA !== '' && result.aliasAKA !== undefined"
                                                     class="text-muted text-uppercase font-weight-bold"
                                                     style="font-size: xx-small">
                                                    Alias: {{(result.aliasAKA[0].firstName || '') + " " + (result.aliasAKA[0].middleName || '') + " " + (result.aliasAKA[0].lastName || '')}}
                                                </div>
                                                <div v-if="result.starNumber !== '' && result.starNumber !== undefined" class="text-muted text-uppercase font-weight-bold" style="font-size: xx-small">Star Number: {{result.starNumber}}</div>
                                            </div>
                                            <!-- Additional Icons Top Right -->
                                            <div class="ml-auto">
                                                <div v-if="checkAuthorization === true" style="width: 32px;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-card>
                    </div>

                    <div v-else-if="selectedTab === 'requiredFormItems'" class="h-100">
                        <b-card style="width: 100%; height: 100%; margin-bottom: 10px; border: 0; border-radius: 0" no-body class="pageBackground">
                            <div slot="header" style="height: 29px" class="d-flex align-items-center">
                                <strong>Required Form Items</strong>
                            </div>
                            <div class="d-flex flex-column" style="overflow-y: auto; overflow-x: hidden; height: 100%; padding: 10px 0 3px 10px;">
                                <div v-for="category in Object.keys(formErrors)" :key="'categoryErrors' + category + iter">
                                    <b>{{formErrors[category].name}}</b>
                                    <div v-for="item in Object.keys(formErrors[category].items)" style="padding: 0 0 3px 20px;" :key="'itemErrors' + item + iter">
                                        <div v-if="formErrors[category].items[item].errorsArr.length > 0">
                                            {{formErrors[category].items[item].itemName + " " + formErrors[category].items[item].itemIndex}}
                                            <div v-for="error in formErrors[category].items[item].errorsArr"
                                                 :key="'itemError' + error._id"
                                                 class="validationText"
                                                 style="color: #f86c6b; padding-left: 20px; padding-bottom: 3px; cursor: pointer"
                                                 @click="formErrors[category].singleItem === true ? openSingleItemTab({categoryId: category, itemId: item, fieldId: error._id,  open: true}) : openTab({categoryId: category, itemId: item, fieldId: error._id,  open: true})">
                                                 {{error.name}} {{error.description !== '' ? ' - '+error.description: ''}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-card>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import MaskedInput from 'vue-text-mask'
import NavBar from "@/views/expansion_modules/reports/side_bar_components/NavBar";
import ReportComments from "@/views/expansion_modules/reports/side_bar_components/ReportComments";
import ReportRevisions from "@/views/expansion_modules/reports/side_bar_components/ReportRevisions";
import ReportFiles from "@/views/expansion_modules/reports/side_bar_components/ReportFiles";
import csvImport from "./csvImport.vue";
import PubSub from 'pubsub-js'
import Report from '@/services/report';
import Category from "@/views/expansion_modules/reports/case_components/Category";
import PdfPreview from "@/views/expansion_modules/reports/case_components/PdfPreview";
import Vue from "vue";
import {ClientTable} from 'vue-tables-2';
import {v4 as uuidv4} from 'uuid';

Vue.use(ClientTable)

export default {
    name: "Case",
    components: {
        Category,
        MaskedInput,
        NavBar,
        ReportComments,
        ReportRevisions,
        ReportFiles,
        PdfPreview,
        csvImport
    },
    props: {
      caseId: String,
      reportId: String,
    },
    data() {
        return {
            google: null,
            deleteReportModal: false,
            choosePdfModal: false,
            iter: 0,
            createStartingSupplement: false,
            showPdf: false,
            newUniqueKey: 0,
            gettingReport: true,
            assignUserModel: false,
            reportStatus: '',
            isAdmin: false,
            isSupervisor: false,
            isPREA: false,
            isRecords: false,
            isSavable: false,
            pdfReady: false,
            savable: [],
            reportNames: [],
            users: [],
            user: {
                name: null,
                id: null
            },
            assignUserTable: {
                columns: ['email'],
                tOptions: {
                    orderBy: {column: 'email', ascending: true},
                    filterable: ['email'],
                    sortable: ['email'],
                    headings: {
                        email: "Email Address",
                    },
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    pagination: {
                        chunk: 5,
                        edge: false,
                        nav: 'scroll'
                    },
                    perPage: 15,
                    skin: 'table table-striped table-hover',
                },
                theme: 'bootstrap4',
            },
            additionalInfoTabs: [
                {type: 'info',              icon: ['fas', 'circle-info'],          title: 'Info',           supervisorOnly: false,},
                {type: 'notes',             icon: ['fas', 'comment-dots'],         title: 'Notes',          supervisorOnly: false,},
                {type: 'files',             icon: ['far', 'file'],                 title: 'Files',          supervisorOnly: false,},
                {type: 'users',             icon: ['fas', 'users'],                title: 'Users',          supervisorOnly: true, },
                {type: 'searchResults',     icon: ['fas', 'magnifying-glass'],     title: 'Search Results', supervisorOnly: false,},
                {type: 'revisions',         icon: ['fas', 'pen-to-square'],        title: 'Revisions',      supervisorOnly: false,},
                {type: 'requiredFormItems', icon: ['fas', 'triangle-exclamation'], title: 'Validation',     supervisorOnly: false,},
            ],
            leftBool: false,
            rightBool: false,
            actions: [
                {text: 'Additional PDF',       value: 'pdf',           credentials: 'user'       },
                {text: 'Change Report Status', value: 'change status', credentials: 'supervisor' },
                {text: 'Close All',            value: 'close',         credentials: 'user'       },
                {text: 'Delete Report',        value: 'delete',        credentials: 'admin'      },
                {text: 'Download Case',        value: 'download case', credentials: 'supervisor' },
                {text: 'Download Report',      value: 'download',      credentials: 'user'       },
                {text: 'Import CSV',           value: 'import',        credentials: 'user'       },
                {text: 'Open All',             value: 'open',          credentials: 'user'       },
                {text: 'Preview Report',       value: 'preview',       credentials: 'user'       },
            ],
            reportStatuses: [
                {text: 'Approved', value: 'Approved'},
                {text: 'Disposed', value: 'Disposed'},
                {text: 'In Progress', value: 'In Progress'},
                {text: 'Pending Approval', value: 'Pending Approval'},
                {text: 'Rejected', value: 'Rejected'},
            ],
            selectedTemplate: '',
            templates: [],
            addReportModal: false,
            changeReportStatusModal: false,
            selectedReport: '',
            caseObj: {
                assigned_users: []
            },
            template: {},
            report: {
                report: {
                    categories: []
                },
                templateName: '',
                notes: [],
                assigned_user: {
                    id: '',
                    name: ''
                },
                status: '',
                revisions: []
            },
            selectedTab: '',
            formErrors: {},
            searchResults: [],
            tempFieldId: null,
            downloadLink: null,
            pdfList: [],
            selectedPdf: '',
            selectedPdfName: '',
            pdfFile: null,
            pdfName: '',
            pdfFileModal: false,
            gettingPdf: false,
            searchTerm: '',
            importFileModal: true,
            importLoading: false
        }
    },
    created() {
        this.google = document.getElementById('google_import');
        this.loadCase();
        //this is used to house the download link for the pdf
        this.downloadLink = document.createElement('a');
        //this is the autosave function
        PubSub.subscribe('confirmAutoSaveRequest', () => {
            this.report.last_saved = new Date().toLocaleString();
            Report.putReport(this.report._id, this.report);
        });
        PubSub.subscribe('toggleItemIcon', (msg, data) => {
            document.getElementById('sidebar' + data).classList.toggle('fa-angle-right');
            document.getElementById('sidebar' + data).classList.toggle('fa-angle-down');
        });
        PubSub.subscribe('toggleSingleItemIcon', (msg, data) => {
            if (this.template.categories[data.index] !== undefined && this.template.categories[data.index].singleItem === true) {
                document.getElementById('sidebar' + data.itemId).classList.toggle('fa-angle-right');
                document.getElementById('sidebar' + data.itemId).classList.toggle('fa-angle-down');
            }
        });
        PubSub.subscribe('categoryIsSavable', (msg, data) => {
            if (document.getElementById('cautionCategory' + data.categoryId) !== null) {
                document.getElementById('cautionCategory' + data.categoryId).style.display = (data.isSavable === true ? "none" : "inline-block");
            }
        })
        PubSub.subscribe('itemIsSavable', (msg, data) => {
            if (document.getElementById('cautionItem' + data.itemId) !== null) {
                document.getElementById('cautionItem' + data.itemId).style.display = (data.isSavable === true ? "none" : "inline-block");
            }
        })
        PubSub.subscribe('requiredFormItems', (msg, data) => {
            if (this.formErrors[data.categoryId] && this.formErrors[data.categoryId].items[data.itemId]
                && data.errorsArr.length <= 0) {
                delete this.formErrors[data.categoryId].items[data.itemId];
                this.iter++;
                if (Object.keys(this.formErrors[data.categoryId].items).length === 0) {
                    delete this.formErrors[data.categoryId];
                    this.iter++;
                }
            } else if (data.errorsArr.length > 0) {
                if (!this.formErrors[data.categoryId]) {
                    Vue.set(this.formErrors, data.categoryId, {name: data.categoryName, items: {}, singleItem: data.singleItem});
                }
                Vue.set(this.formErrors[data.categoryId].items, data.itemId, {
                    itemName: data.itemName,
                    itemIndex: data.itemIndex,
                    errorsArr: data.errorsArr,
                });
            }
        })
        PubSub.subscribe('displaySearchResults', (msg, data) => {
            this.searchResults = data.results.sort((a, b) => {
                if(a.lastName) {
                    if (b.lastName) {
                        return a.lastName.localeCompare(b.lastName);
                    } else if (b.officerName.split(',')[1]) {
                        return a.lastName.localeCompare(b.officerName.split(',')[1]);
                    } else {
                        return -1
                    }
                } else if (a.officerName.split(',')[1]) {
                    if (b.lastName) {
                        return a.officerName.split(',')[1].localeCompare(b.lastName);
                    } else if (b.officerName.split(',')[1]) {
                        return a.officerName.split(',')[1].localeCompare(b.officerName.split(',')[1]);
                    } else {
                        return -1;
                    }
                } else {
                    return -1;
                }
            });
            this.tempFieldId = data.fieldId;
            this.tempItemId = data.itemId;
            this.selectedTab = 'searchResults';
            if (document.getElementById('column2').classList.contains('closeRightColumn')) {
                this.rightBool = !this.rightBool;
                document.getElementById('column2').classList.toggle('closeRightColumn');
                document.getElementById('column2').classList.toggle('openRightColumn');
            }
        });
    },
    methods: {
        findMatchingField(itemKey, itemValue) {
            this.template.categories.forEach((category, categoryIndex) => {
                let index = category.item.fields.findIndex(field => {
                    return field.name === itemKey;
                });
                if (index !== -1) {
                    this.report.report.categories[categoryIndex].items[0][this.template.categories[categoryIndex].item.fields[index]._id] = itemValue;
                }
            });
        },
        async importWholeReport(item) {
            this.importLoading = true;
            let categoryCount = 0;
            for (let i = 0; i < this.report.report.categories.length; i++) {
                await this.wipeCategory(this.report.report.categories[i]._id, i);
                this.addItem({categoryId:this.report.report.categories[i]._id, index: i}, () => {
                    categoryCount++;
                    if (categoryCount === this.report.report.categories.length) {
                        Object.keys(item).forEach(itemKey => {
                            this.findMatchingField(itemKey, item[itemKey]);
                        });
                    }
                });
            }
            this.importLoading = false;
        },
        async importSingleCategory(fieldMap, itemList, categoryId, categoryIndex) {
            this.importLoading = true;
            await this.wipeCategory(categoryId, categoryIndex);
            if (this.template.categories[categoryIndex].singleItem === false) {
                let count = 0;
                for (let i = 0; i < itemList.length; i++) {
                    this.addItem({categoryId: categoryId, index: categoryIndex}, () => {
                        count++;
                        if (count === itemList.length) {
                            let itemCount = 0;
                            itemList.forEach((item, index) => {
                                let fieldCount = 0;
                                Object.keys(fieldMap).forEach(fieldId => {
                                    if (fieldMap[fieldId].name !== '') {
                                        this.report.report.categories[categoryIndex].items[index][fieldId] = item[fieldMap[fieldId].name];
                                    }
                                    fieldCount++;
                                    if (fieldCount === Object.keys(fieldMap).length) {
                                        itemCount++;
                                        if (itemCount === itemList.length) {
                                            this.importLoading = false;
                                        }
                                    }
                                });
                            });
                        }
                    });
                }
            } else {
                this.addItem({categoryId: categoryId, index: categoryIndex}, () => {
                    let fieldCount = 0;
                    Object.keys(fieldMap).forEach(fieldId => {
                        if (fieldMap[fieldId].name !== '') {
                            this.report.report.categories[categoryIndex].items[0][fieldId] = itemList[0][fieldMap[fieldId].name];
                        }
                        fieldCount++;
                        if (fieldCount === Object.keys(fieldMap).length) {
                            this.importLoading = false;
                        }
                    });
                });
            }
        },
        wipeCategory(categoryId, categoryIndex) {
            let index = this.report.report.categories.findIndex(category => {
                return category._id === categoryId;
            });
            if (index !== -1) {
                for (let i = this.report.report.categories[index].items.length - 1; i > -1; i--) {
                    this.removeItem({
                        itemIndex: i,
                        categoryIndex: categoryIndex,
                        categoryId: categoryId,
                        itemId: this.report.report.categories[index].items[i]._id
                    });
                }
            }

        },
        changeCasePREA(bool) {
            this.caseObj.isPREA = bool;
            Report.putCase(this.caseId, this.caseObj).then(() => {
                this.$mToast({
                    title: 'PREA Status Changed',
                    style: 'success',
                });
            }).catch(() => {
                this.$mToast({
                    title: 'Error: Failed to change case status.',
                    style: 'error',
                });
            });
        },
        filterArr(arr, searchKey, keys) {
            if (searchKey.split(" ").length <= 1) {
                return arr.filter(obj => {
                    return Object.keys(obj).some(key => {
                        let value = (typeof obj[key] === 'number' ? obj[key].toString() : obj[key])
                        return value !== '' && value !== undefined && typeof value === 'string' && keys.some(_ => {
                            return _ === key
                        }) && (value.toLowerCase().includes(searchKey.toLowerCase()) || searchKey.toLowerCase().includes(value.toLowerCase()))
                    })
                });
            } else {
                let arr1 = this.filterArr(arr, searchKey.substring(searchKey.indexOf(' ') + 1), keys);
                let arr2 = this.filterArr(arr, searchKey.substring(0, searchKey.indexOf(' ')), keys);
                return arr1.filter(_ => arr2.includes(_))
            }
        },
        assignPrimaryUser(user) {
            //work on this, still breaks when new user is made primary user
            let index = this.caseObj.assigned_users.findIndex(caseUser => {
                return user === caseUser;
            })
            if (index !== -1) {
                this.caseObj.assigned_users.sort((a, b) => {
                    if (a === user)
                        return -1;
                    if (b === user)
                        return 1;
                    return 0;
                })
                Report.setPrimaryUser(this.caseObj._id, this.caseObj.assigned_users).catch(() => {
                    this.$mToast({
                        title: 'Error: Failed to set primary user.',
                        style: 'error',
                    });
                });
            } else {
                Report.assignCase(this.caseObj._id, user).then(async () => {
                    await this.loadCase(() => {
                        this.caseObj.assigned_users = this.caseObj.assigned_users.sort((a, b) => {
                            if (a === user)
                                return -1;
                            if (b === user)
                                return 1;
                            return 0;
                        })
                        Report.setPrimaryUser(this.caseObj._id, this.caseObj.assigned_users).catch(() => {
                            this.loadCase();
                            this.$mToast({
                                title: 'Error: Failed to set primary user.',
                                style: 'error',
                            });
                        });
                    });
                }).catch(() => {
                    this.$mToast({
                        title: 'Error: Failed to assign user.',
                        style: 'error',
                    });
                });
            }
        },
        checkPrimaryUser(userId) {
            return userId === this.caseObj.assigned_users[0];
        },
        selectPdf() {
            Report.getReportFiles('wcsofiles').then(response => {
                this.pdfList = response.data.map(pdf => {
                    return {text: pdf.name[0], value: pdf.name[0]};
                });
                this.choosePdfModal = true;
            }).catch(() => {
                this.$mToast({
                    title: 'Error: Failed to get pdfs.',
                    style: 'error',
                });
            });
        },
        getSelectedPdf() {
            this.gettingPdf = true;
            Report.downloadFile({reportId: 'wcsofiles', fileName: this.selectedPdf}).then(async response => {
                this.pdfFile = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                this.gettingPdf = false;
                this.pdfName = this.selectedPdfName;
                this.choosePdfModal = false;
                this.selectedPdf = '';
                this.pdfFileModal = true;
            }).catch(() => {
                this.$mToast({
                    title: 'Error: Failed to get pdf.',
                    style: 'error',
                });
            });
        },
        selectResult(result) {
            PubSub.publish('resultSelected'+this.tempItemId, {result: result, fieldId: this.tempFieldId});
            this.searchResults = [];
            this.tempFieldId = null;
            this.tempItemId = null;
            this.selectedTab = 'info';
            this.searchTerm = '';
        },
        assignCase(caseId, userId) {
            Report.assignCase(caseId, userId).then(() => {
                this.loadCase();
            }).catch(() => {
                this.$mToast({
                    title: 'Error: Failed to assign user.',
                    style: 'error',
                });
            });
        },
        unassignCase(caseId, userId) {
            Report.unassignCase(caseId, userId).then(() => {
                this.loadCase();
            }).catch(() => {
                this.$mToast({
                    title: 'Error: Failed to unassign user.',
                    style: 'error',
                });
            });
        },
        checkRevisions() {
            if (this.report.revisions.length === 0) {
                return false;
            } else {
                for (const revision of this.report.revisions) {
                    if (revision.completed === false) {
                        return true;
                    }
                }
                return false;
            }
        },
        removeItem(data) {
            this.report.report.categories[data.categoryIndex].items.splice(data.itemIndex, 1);
            this.report.report.categories[data.categoryIndex].soundexItems.splice(data.itemIndex, 1);
            if (this.formErrors[data.categoryId] && this.formErrors[data.categoryId].items[data.itemId]) {
                delete this.formErrors[data.categoryId].items[data.itemId];
                this.iter++;
                if (Object.keys(this.formErrors[data.categoryId].items).length === 0) {
                    delete this.formErrors[data.categoryId];
                    this.iter++;
                }
            }
        },
        addItem(data, callback) {
            Report.getNewItem(this.report._id, data.categoryId).then(response => {
                this.report.report.categories[data.index].items.push(response.data.item);
                this.report.report.categories[data.index].soundexItems.push(response.data.soundexItem);
                if (callback) {
                    callback();
                }
            }).catch(() => {
                this.$mToast({
                    title: 'Error: Failed to add item.',
                    style: 'error',
                });
            });
        },
        duplicateItem(data) {
            let item = JSON.parse(JSON.stringify(data.item));
            item._id = uuidv4();
            let soundexItem = this.report.report.categories[data.categoryIndex].soundexItems[data.itemIndex];
            soundexItem._id = uuidv4();
            this.report.report.categories[data.categoryIndex].items.push(item);
            this.report.report.categories[data.categoryIndex].soundexItems.push(soundexItem);
            this.$mToast({
                title: 'Item Duplicated!',
                style: 'success',
            });
        },
        rejectReport() {
            this.report.status = 'Rejected';
            Report.putReport(this.report._id, this.report).then(() => {
                this.$mToast({
                    title: 'Rejected',
                    style: 'success',
                });
                location.href = '/#/cases/supervisor';
            }).catch(() => {
                this.$mToast({
                    title: 'Error: Rejection Failed',
                    style: 'error',
                });
            });
        },
        approveReport() {
            this.report.status = 'Approved';
            this.report.approval_date = Date.now();
            Report.putReport(this.report._id, this.report).then(() => {
                this.$mToast({
                    title: 'Report Approved',
                    style: 'success',
                });
                location.href = '/#/cases/supervisor';
            }).catch(() => {
                this.$mToast({
                    title: 'Error: Approval Failed',
                    style: 'error',
                });
            });
        },
        loadCase(callback) {
            Report.getCaseById(this.caseId).then(response => {
                if (response.data.user.role === 'admin') {
                    this.isAdmin = true;
                    this.isSupervisor = true;
                    this.isPREA = true;
                } else if (response.data.user.role === 'supervisor') {
                    this.isSupervisor = true;
                } else if (response.data.user.role === 'PREA') {
                    this.isPREA = true;
                    this.isSupervisor = true;
                } else if (response.data.user.role === 'records') {
                    this.isRecords = true;
                }
                this.user.name = response.data.user.name;
                this.user.id = response.data.user.sub;
                this.caseObj = response.data;
                Report.getReportNumber(this.caseObj.reports).then(response => {
                    this.reportNames = response.data.sort((a,b) => {
                        if (parseInt(a.text.split('.')[1].split(' ')[0]) > parseInt(b.text.split('.')[1].split(' ')[0])) {
                            return 1;
                        }
                        if (parseInt(a.text.split('.')[1].split(' ')[0]) < parseInt(b.text.split('.')[1].split(' ')[0])) {
                            return -1;
                        }
                        return 0;
                    });
                    if (this.reportNames.length > 0) {
                        if (this.reportId !== undefined) {
                            this.selectedReport = this.reportId;
                        } else {
                            this.selectedReport = this.reportNames[0].value;
                        }
                    } else {
                        this.template = {};
                        this.report = {
                            report: {categories: []},
                            templateName: '',
                            notes: [],
                            assigned_user: {
                                id: '',
                                name: ''
                            },
                            status: '',
                            revisions: []
                        };
                        this.selectedReport = '';
                        this.selectedTemplate = '';
                        if (this.selectedTab !== 'users') {
                            this.selectedTab = '';
                        }
                    }
                    if (callback !== undefined) {
                        callback();
                    }
                }).catch(() => {
                    this.$mToast({
                        title: 'Error: Failed to get reports.',
                        style: 'error',
                    });
                });
            }).catch(() => {
                this.$mToast({
                    title: 'Error: Failed to get case info.',
                    style: 'error',
                });
            });
        },
        loadReport() {
            if (this.selectedReport !== '') {
                this.gettingReport = true;
                Report.getReportById(this.selectedReport).then(response => {
                    let tempReport = response.data;
                    Report.getTemplateById(tempReport.templateType).then(async response => {
                        let tempTemplate = response.data;
                        //remove old categories if the template was changed
                        for (const [index, category] of tempReport.report.categories.entries()) {
                            let removeReport = tempTemplate.categories.findIndex(template => {
                                return template._id === category._id;
                            });
                            if (removeReport === -1) {
                                tempReport.report.categories.splice(index, 1);
                            }
                        }
                        //add new categories if the template was changed
                        for (const category of tempTemplate.categories) {
                            let index = tempReport.report.categories.findIndex(report => {
                                return report._id === category._id;
                            });
                            if (index === -1) {
                                await Report.addCategory(category).then(response => {
                                    tempReport.report.categories.push(response.data);
                                });
                            }
                        }
                        this.gettingReport = false;
                        this.newUniqueKey++;
                        this.formErrors = Object.assign({}, {});
                        this.template = tempTemplate;
                        this.report = tempReport;
                        if (!this.report.report) {
                            this.report.report = {categories: []};
                        }
                    }).catch(() => {
                        this.$mToast({
                            title: 'Error: Failed to get report.',
                            style: 'error',
                        });
                    });
                }).catch(() => {
                    this.$mToast({
                        title: 'Error: Failed to get report.',
                        style: 'error',
                    });
                });
            }
        },
        deleteReport() {
            let index = this.caseObj.reports.findIndex(report => {
                return report.report === this.selectedReport;
            });
            if (index === 0) {
                this.deleteReportModal = false;
                this.$mToast({
                    title: 'Warning: Main report cannot be deleted',
                    style: 'danger',
                });
            } else if (index !== -1) {
                this.caseObj.reports[index].hidden = true
                Report.hideReport(this.selectedReport, this.caseObj._id, this.caseObj).then(() => {
                    location.href = '/#/case/' + this.caseObj._id;
                    this.loadCase();
                    this.deleteReportModal = false;
                    this.$mToast({
                        title: 'Report Deleted',
                        style: 'success',
                    });
                }).catch(() => {
                    this.$mToast({
                        title: 'Error: Failed To Delete Report',
                        style: 'error',
                    });
                });
            } else {
                this.$mToast({
                    title: 'Error: Report Not Valid',
                    style: 'error',
                });
            }
        },
        createPdfDownloadLink(pdf) {
            let download = Buffer.from(pdf, 'base64');
            this.downloadLink.href = window.URL.createObjectURL(new Blob([download], {type: 'application/pdf'}));
            this.downloadLink.setAttribute('download', this.caseObj.caseNumber);
            document.body.appendChild(this.downloadLink);
            this.downloadLink.click();
        },
        submitReport() {
            this.report.status = 'Pending Approval';
            this.report.time_submitted = new Date().toLocaleString();
            Report.putReport(this.report._id, this.report).then(() => {
                this.$mToast({
                    title: 'Report Submitted For Approval',
                    style: 'success',
                });
                location.href = '/#/cases';
            }).catch(() => {
                this.$mToast({
                    title: 'Error: Failed to Submit Report',
                    style: 'error',
                });
            })
        },
        createNewReport() {
            Report.postReport({
                supplementOf: this.createStartingSupplement === true ? 'supplement made first' : '',
                templateType: this.selectedTemplate,
                report: {},
                caseId: this.caseObj._id,
                isPREA: this.caseObj.isPREA,
                assigned_user: {name: this.user.name, id: this.user.id}
            }, this.createStartingSupplement === true ? 'true' : 'false').then(reportResult => {
                this.addReportModal = false;
                this.createStartingSupplement = false;
                Report.getCaseById(this.caseId).then(response => {
                    this.caseObj = response.data;
                    this.selectedReport = reportResult.data._id;
                    Report.getReportNumber(this.caseObj.reports).then(response2 => {
                        this.reportNames = response2.data.sort((a,b) => {
                            if (parseInt(a.text.split('.')[1].split(' ')[0]) > parseInt(b.text.split('.')[1].split(' ')[0])) {
                                return 1;
                            }
                            if (parseInt(a.text.split('.')[1].split(' ')[0]) < parseInt(b.text.split('.')[1].split(' ')[0])) {
                                return -1;
                            }
                            return 0;
                        });
                    });
                });
                this.$mToast({
                    title: 'Report Created!',
                    style: 'success',
                });
            }).catch(() => {
                this.$mToast({
                    title: 'Error: Failed to create new report',
                    style: 'error',
                });
            });
        },
        createSupplement() {
            Report.postReport({
                    supplementOf: this.report._id,
                    templateType: this.report.templateType,
                    report: {},
                    caseId: this.caseObj._id,
                    isPREA: this.caseObj.isPREA,
                    assigned_user: {id: this.user.id, name: this.user.name}
                }).then(reportResult => {
                this.addReportModal = false;
                Report.getCaseById(this.caseId).then(response => {
                    this.caseObj = response.data;
                    this.selectedReport = reportResult.data._id;
                    Report.getReportNumber(this.caseObj.reports).then(response2 => {
                        this.reportNames = response2.data.sort((a,b) => {
                            if (parseInt(a.text.split('.')[1].split(' ')[0]) > parseInt(b.text.split('.')[1].split(' ')[0])) {
                                return 1;
                            }
                            if (parseInt(a.text.split('.')[1].split(' ')[0]) < parseInt(b.text.split('.')[1].split(' ')[0])) {
                                return -1;
                            }
                            return 0;
                        });
                    });
                });
                this.$mToast({
                    title: 'Supplement Created!',
                    style: 'success',
                });
            }).catch(() => {
                this.$mToast({
                    title: 'Error: Failed to create supplement',
                    style: 'error',
                });
            });
        },
        saveCategory(obj) {
            if (this.savable[obj.index] !== obj.savable) {
                this.savable[obj.index] = obj.savable;
                this.isSavable = true;
                for (let e of this.savable) {
                    if (!e) {
                        this.isSavable = false;
                        break;
                    }
                }
            }
            this.report.report.categories[obj.index] = obj.category;
        },
        canSaveReport() {
            return ((this.report.assigned_user.id === this.user.id || this.isSupervisor === true)
                    && this.selectedReport !== '' && this.report.status !== 'Approved' && this.report.status !== 'Disposed') ||
                    ((this.report.status === 'Approved' || this.report.status === 'Disposed') && this.isAdmin === true)
        },
        saveReport() {
            this.report.last_saved = new Date().toLocaleString();
            Report.putReport(this.report._id, this.report).then(() => {
                let temp = this.selectedTab;
                this.selectedTab = '';
                this.selectedTab = temp;
            });
        },
        async beginAction(action) {
            if (action === 'close') {
                PubSub.publish('closeOpenAllOpenIncidentTabs', {bool: false});
            } else if (action === 'open') {
                PubSub.publish('closeOpenAllOpenIncidentTabs', {bool: true});
            } else if (action === 'add') {
                Report.getNamesOfTemplates().then(response => {
                    this.templates = response.data;
                    this.addReportModal = true;
                });
            } else if (action === 'supplement first') {
                Report.getNamesOfTemplates().then(response => {
                    this.templates = response.data;
                    this.createStartingSupplement = true;
                    this.addReportModal = true;
                });
            } else if (action === 'delete') {
                this.deleteReportModal = true;
            } else if (action === 'change status') {
                this.reportStatus = this.report.status;
                this.changeReportStatusModal = true;
            } else if (action === 'preview') {
                await this.saveReport();
                this.showPdf = true;
            } else if (action === 'download') {
                await this.saveReport();
                this.downloadPdfMethod();
            } else if (action === 'supplement') {
                this.createSupplement();
            } else if (action === 'download case') {
                await this.saveReport();
                this.downloadCaseFiles();
            } else if (action === 'pdf') {
                this.selectPdf();
            } else if (action === 'make case PREA') {
                this.changeCasePREA(true);
            } else if (action === 'make case not PREA') {
                this.changeCasePREA(false);
            } else if (action === 'import') {
                this.$bvModal.show('importFileModal');
            }
            this.selectedAction = '';
        },
        downloadPdfMethod() {
            PubSub.publish('downloadPDFReport', {});
        },
        downloadCaseFiles() {
            window.open(Report.getCaseDocuments(this.caseObj._id));
        },
        changeReportStatus() {
            Report.putReportStatus(this.report._id, this.reportStatus).then(() => {
                this.report.status = this.reportStatus;
                this.$mToast({
                    title: 'Report status changed to (' + this.reportStatus + ')',
                    style: 'success',
                });
            }).catch(() => {
                this.$mToast({
                    title: 'Error',
                    text: "Error: Failed to change report status",
                    style: 'error'
                });
            });
            this.changeReportStatusModal = false;
        },
        changeTab(tab) {
            this.selectedTab = tab.type;
            if (tab.type === 'users') {
                Report.getUsers().then(response => {
                    this.users = response.data;
                })
            }
            if (document.getElementById('column2').classList.contains('closeRightColumn')) {
                this.rightBool = !this.rightBool;
                document.getElementById('column2').classList.toggle('closeRightColumn');
                document.getElementById('column2').classList.toggle('openRightColumn');
            }
        },
        addNote(note) {
            this.report.notes.push(note);
            Report.putReport(this.report._id, this.report).then(() => {
                Report.getReportNotes(this.report._id).then(response => {
                    this.report.notes = response.data.notes
                })
            }).catch(() => {
                this.$mToast({
                    title: 'Error: Failed to add note.',
                    style: 'error',
                });
            });
        },
        deleteNote(note) {
            let index = this.report.notes.findIndex(deleteNote => {
                return deleteNote._id === note._id;
            });
            this.report.notes.splice(index, 1);
            Report.putReport(this.report._id, this.report).then(() => {
                Report.getReportNotes(this.report._id).then(response => {
                    this.report.notes = response.data.notes
                })
            }).catch(() => {
                this.$mToast({
                    title: 'Error: Failed to delete note.',
                    style: 'error',
                });
            });
        },
        editNote(note) {
            let index = this.report.notes.findIndex(editNote => {
                return editNote._id === note._id;
            });
            this.report.notes[index] = Object.assign(this.report.notes[index], note);
            Report.putReport(this.report._id, this.report).then(() => {
                Report.getReportNotes(this.report._id).then(response => {
                    this.report.notes = response.data.notes
                })
            }).catch(() => {
                this.$mToast({
                    title: 'Error: Failed to edit note.',
                    style: 'error',
                });
            });
        },
        addRevision(note) {
            this.report.revisions.push(note);
            Report.putReport(this.report._id, this.report).catch(() => {
                this.$mToast({
                    title: 'Error: Failed to add revision.',
                    style: 'error',
                });
            });
        },
        deleteRevision(note) {
            let index = this.report.revisions.findIndex(deleteNote => {
                return deleteNote._id === note._id;
            });
            this.report.revisions.splice(index, 1);
            Report.putReport(this.report._id, this.report).catch(() => {
                this.$mToast({
                    title: 'Error: Failed to delete revision.',
                    style: 'error',
                });
            });
        },
        editRevision(note) {
            let index = this.report.revisions.findIndex(editNote => {
                return editNote._id === note._id;
            });
            this.report.revisions[index] = Object.assign(this.report.revisions[index], note);
            Report.putReport(this.report._id, this.report).catch(() => {
                this.$mToast({
                    title: 'Error: Failed to edit revision.',
                    style: 'error',
                });
            });
        },
        changeRevisionStatus(note) {
            let index = this.report.revisions.findIndex(editNote => {
                return editNote._id === note._id;
            });
            this.report.revisions[index] = Object.assign(this.report.revisions[index], {completed: !this.report.revisions[index].completed});
            Report.putReport(this.report._id, this.report).catch(() => {
                this.$mToast({
                    title: 'Error: Failed to change revision status.',
                    style: 'error',
                });
            });
        },
        focusTab(data) {
            PubSub.publish('focusTab', data);
        },
        openTab(data) {
            PubSub.publish('openTab', data);
        },
        openSingleItemTab(data) {
            PubSub.publish('openSingleItemTab', data);
        },
        toggleLeftColumn() {
            if(document.getElementById('column1').classList.contains('openLeftColumn') || document.getElementById('column1').classList.contains('closeLeftColumn')) {
                if (document.getElementById('column1').classList.contains('openLeftColumn')) {
                    this.leftBool = !this.leftBool;
                }
                document.getElementById('column1').classList.toggle('closeLeftColumn');
                document.getElementById('column1').classList.toggle('openLeftColumn');
                if (document.getElementById('column1').classList.contains('openLeftColumn')) {
                    setTimeout(() => {
                        this.leftBool = !this.leftBool;
                    }, 75);
                }
            } else {
                document.getElementById('column1').classList.toggle('closeLeftColumn');
                this.leftBool = !this.leftBool;
            }
        },
        toggleRightColumn() {
            if(document.getElementById('column2').classList.contains('openRightColumn') || document.getElementById('column2').classList.contains('closeRightColumn')) {
                if (document.getElementById('column2').classList.contains('openRightColumn')) {
                    this.rightBool = !this.rightBool;
                }
                document.getElementById('column2').classList.toggle('closeRightColumn');
                document.getElementById('column2').classList.toggle('openRightColumn');
                if (document.getElementById('column2').classList.contains('openRightColumn')) {
                    setTimeout(() => {
                        this.rightBool = !this.rightBool;
                    }, 229);
                }
            } else {
                document.getElementById('column2').classList.toggle('closeRightColumn');
                this.rightBool = !this.rightBool;
            }
        },
    },
    computed: {
        checkAuthorization() {
            if (this.report.status === 'In Progress'  || this.report.status === 'Rejected') {
                return this.report.assigned_user.id === this.user.id || this.isSupervisor === true;
            } else if (this.report.status === 'Pending Approval') {
                return this.isSupervisor === true;
            } else if (this.report.status === 'Approved' || this.report.status === 'Disposed') {
                return this.isAdmin === true;
            } else {
                return false;
            }
        },
        checkUserIsAssigned() {
            if (this.caseObj.assigned_users.length > 0) {
                let count = 0;
                for (let i = 0; i < this.caseObj.assigned_users.length; i++) {
                    if (this.caseObj.assigned_users[i] === this.user.id) {
                        return true;
                    }
                    count++;
                    if (count === this.caseObj.assigned_users.length) {
                        return false;
                    }
                }
            } else {
                return false;
            }
        },
        checkForPrimaryReport() {
            if (this.reportNames.length > 0) {
                let count = 0;
                for (let i = 0; i < this.reportNames.length; i++) {
                    let index = this.reportNames[i].text.search('.1');
                    count++;
                    if (index !== -1) {
                        return false;
                    }
                    if (count === this.reportNames.length) {
                        return true;
                    }
                }
            } else {
                return true;
            }
        },
    },
    watch: {
        selectedReport() {
            this.savable = [];
            this.newUniqueKey++;
            location.href = '/#/case/' + this.caseObj._id + '/' + this.selectedReport;
            this.loadReport();
        },
        selectedPdf() {
            if (this.selectedPdf !== '') {
                this.selectedPdfName = this.selectedPdf;
            }
        },
    }
}
</script>

<style scoped>
.mainContainer {
    height: calc(100vh - 152px);
    margin: -24px -30px 0 -30px;
}
.pageBackground {
    background-color: #1c1c1c !important;
}
.carrotIconToggler {
    margin: 10px
}
.openLeftColumn {
    white-space: nowrap;
    animation: openLeftColumnAnimation 300ms;
    animation-fill-mode: forwards;
}
@keyframes openLeftColumnAnimation {
    from {width: 0; min-width: 0}
    to {width: 14%; min-width: 14%}
}
.closeLeftColumn {
    white-space: nowrap;
    animation: closeLeftColumnAnimation 300ms;
    animation-fill-mode: forwards;
}
@keyframes closeLeftColumnAnimation {
    from {width: 14%; min-width: 14%}
    to {width: 0; min-width: 0}
}
.openRightColumn {
    white-space: nowrap;
    animation: openRightColumnAnimation 300ms;
    animation-fill-mode: forwards;
}
@keyframes openRightColumnAnimation {
    from {width: 0; min-width: 0}
    to {width: 20%; min-width: 20%}
}
.closeRightColumn {
    white-space: nowrap;
    animation: closeRightColumnAnimation 300ms;
    animation-fill-mode: forwards;
}
@keyframes closeRightColumnAnimation {
    from {width: 20%; min-width: 20%}
    to {width: 0; min-width: 0}
}
.caseHeader > div {
    margin: 0 10px;
}
.caseHeader > div:first-child {
    margin-left: 0;
}
.caseHeader > div:last-child {
    margin-right: 0;
}
.validationText:hover {
    text-decoration: underline;
}
.rightBarIcon:hover {
    background-color: #20a8d888;
}
.card-header:first-child {
    border-radius: 0;
}
</style>